<div
  class="wrapper"
  (mouseover)="mouseover()"
  (mouseout)="mouseout()"
>
<div *ngIf="type == 'internal'">
  <app-form-page *ngIf="!formService.isPreviewSuccess" [type]="type"></app-form-page>
  <app-success-page-config *ngIf="formService.isPreviewSuccess"></app-success-page-config>
</div>
<div *ngIf="!type || type != 'internal'">
  <router-outlet></router-outlet>
</div>

</div>
