<div style="padding: .5rem" #filterComponent>

    <mat-slide-toggle *ngIf="!hideToggle" [checked]="filter.filterEnabled" (change)="filterToggleChanged($event)"
    color="primary"> Apply Filters </mat-slide-toggle>

    <div
      *ngIf="filter.filterEnabled"
      fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap=".5rem"
      style="width: 100%"
    >
      <div *ngFor="let filter of filter.filterItems; let i = index" fxFlex="100">
        <mat-card appearance="outlined" fxFlex="column" fxFlex="100" class="hover-card">
          <div class="delete-icon" fxLayout fxLayoutAlign="end center" fxFlex="100">
            <button *ngIf="enableSecurity" mat-icon-button color="acent" fxLayoutAlign="center center" (click)="filter.isSecurity = !filter.isSecurity">
              <mat-icon>disabled_visible</mat-icon>
            </button>
            <button matTooltip="Remove this filter" fxLayout="column" mat-icon-button color="warn" fxLayoutAlign="center center" (click)="removeFilter(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>

          <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="filterDiv">

            <div  *ngIf="selectFilterAttributeForm.get('form' + i) && !showAttributePicker" [fxFlex]="(componentWidth < 450 && componentWidth > 0) ? '100' : '25'" fxFlex.lt-md="45" fxFlex.lt-sm="75">
              <form [formGroup]="selectFilterAttributeForm">
                <mat-form-field class="noBottomMargin">
                  <mat-label>Attribute</mat-label>
                  <input
                    #boxInput
                    type="text"
                    aria-label="attribute autocomplete"
                    matInput
                    (focus)="setFilterList(i)"
                    formControlName="{{'form' + i}}"
                    [matAutocomplete]="auto2"
                  >
                  <button *ngIf="boxInput.value" matSuffix mat-icon-button aria-label="Clear" (click)="selectFilterAttributeForm.get('form' + i).patchValue('')">
                    <mat-icon class="closeIcon">close</mat-icon>
                  </button>
                  <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete" [displayWith]="displayFn"
                  (optionSelected)="filterAttrSelected($event, filter, i); ">
                    <mat-option *ngFor="let attr of attributesForFilter | async" [value]="attr">
                      <span>{{ attr.name || attr.__id}}</span>
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </form>
            </div>

            <div  *ngIf="fieldPickerMap && showAttributePicker" fxLayout="row" [fxFlex]="(componentWidth < 450 && componentWidth > 0) ? '100' : '25'" fxFlex.lt-md="45" fxFlex.lt-sm="75">
              <mat-form-field class="noBottomMargin">
                <mat-label>Attribute</mat-label>
                <input matInput #filterValueInput [(ngModel)]="filter.attribute">
              </mat-form-field>
              <app-field-picker class="fieldPicker"
                                [inputFieldMap]="fieldPickerMap"
                                (selectedFields)="insertAttributeCursor($event, filter)"
              ></app-field-picker>
            </div>

            <div [fxFlex]="(componentWidth < 450 && componentWidth > 0) ? '100' : '25'" fxFlex.lt-md="45" fxFlex.lt-sm="75" fxLayout.xs="row" fxLayout="column">
              <mat-form-field class="noBottomMargin">
                <mat-label>Operator </mat-label>
                <mat-select [(value)]="filter.operator" [disabled]="maskOperator || !filter.attribute">
                  <mat-option
                    (click)="filterOperatorSelected(i, op)"
                    [value]="op"
                    *ngFor="let op of (ATTR_TO_OPERATORS[filter.attribute] || ATTR_TO_OPERATORS['__templated'])"
                  >{{ OPERATORS[op].name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div  *ngIf="!lockFilterType" [fxFlex]="(componentWidth < 450 && componentWidth > 0) ? '100' : '25'" fxFlex.lt-md="45" fxFlex.lt-sm="75">
              <button
                matTooltip="Filter type"
                mat-button
                fxFlexFill
                [matMenuTriggerFor]="menuFilterTypes"
                [ngClass]="{ 'disabled' : lockFilterType }"
              >
                <div style="margin-top: 30px;" fxLayout fxLayoutAlign="space-between center" class="smallText" fxFlexFill>
                  <mat-icon style="opacity: 70%;" >{{filterTypeLogoMap[filter.filterType].logo}}</mat-icon>
                  <span>{{filterTypeLogoMap[filter.filterType].name}}</span>
                  <mat-icon style="opacity: 70%;">arrow_drop_down</mat-icon>
                </div>
              </button>
              <mat-menu #menuFilterTypes="matMenu">
                <div *ngFor="let op of filterTypes">
                  <button mat-menu-item (click)="onSelectFilterType(filter, op)">
                    <mat-icon>{{ op.icon }}</mat-icon> <span>{{ op.name }}</span>
                  </button>
                </div>
              </mat-menu>
            </div>

            <div  *ngIf="filter.operator !== '#'" fxFlex="30" fxFlex.lt-sm="75" fxLayout="row">
              <div [ngSwitch]="filter.filterType" class="filter-type-wrap" *ngIf="(filter.operator !== '!~' && filter.operator !== '~')">
                <div *ngSwitchCase="'static_filter'" fxLayout="row" fxLayoutAlign="start center">

                  <mat-form-field class="noBottomMargin" >
                    <mat-label>Value</mat-label>
                    <input matInput #filterValueInput [(value)]="filter.value" (keyup)="filterValue(filter, $event)">
                  </mat-form-field>

                  <!-- TODO - this should get merged with common picker -->
                  <app-field-picker class="fieldPicker" fxFlex="5" *ngIf="!lockFilterType"
                    [inputFieldMap]="fieldPickerMapForValue"
                    (selectedFields)="systemFieldValueSelected(filter, $event)"
                  ></app-field-picker>

                </div>
                <div *ngSwitchCase="'page_filter'" class="page-filter-wrap" fxFlexFill>
                  <mat-form-field class="noBottomMargin">
                    <mat-label>Page Filter Options</mat-label>
                    <mat-select  [value]="filter.value" class="page-filter-dropdown" (selectionChange)="pageFilterSelected($event, filter, i)">
                      <mat-select-trigger>
                        {{ filter.displayValue }}
                      </mat-select-trigger>
                      <mat-option *ngFor="let option of pageFilters" [value]="option.value" class="custom-mat-option">
                        <!-- {{ option.displayValue }} -->
                        <div class="pageValueOption" (click)="pageFilterSelected(option, filter, i)">
                          <div fxLayoutAlign="start center">
                            <!-- <div fxLayoutAlign="flex-start center" class="page-option-row-1">{{ option.displayValue }}</div> -->
                            <div fxFlex="40" fxLayoutAlign="flex-end center" class="page-option-row-1">{{ option.panelName }}</div>
                            <div fxFlex="10"><mat-icon>chevron_right</mat-icon></div>
                            <div fxFlex="50" [matTooltip]="option.widgetName" fxLayoutAlign="flex-end center" class="page-option-row-1">{{ option.widgetName }}</div>
                          </div>
                          <div fxLayoutAlign="start center">
                            <div fxFlex="40" fxLayoutAlign="flex-end center" class="page-option-row-2">{{ option.panelType }}</div>
                            <div fxFlex="10"></div>
                            <div fxFlex="40" fxLayoutAlign="flex-end center" class="page-option-row-2">{{ option.widgetType }}</div>
                          </div>
                        </div>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <!-- <div
                    [matTooltip]="filter.displayValue || 'Choose value from page'"
                    fxFlexFill fxLayout="row"
                    class="pageValueSelectionButton"
                    [matMenuTriggerFor]="menu"
                  >
                    <div fxLayout fxLayoutAlign="space-between center" class="smallText" fxFlexFill>
                      <div fxFlex="90" class="selectedFilterDisplayValue">{{filter.displayValue?.length ? filter.displayValue.substring(0, 20) + "..." : "-- select value --"}}</div>
                      <mat-icon class="dropdownIcon" fxLayoutAlign="center center">arrow_drop_down</mat-icon>
                    </div>
                  </div>
      
                  <mat-menu #menu="matMenu" yPosition="below" class="page-value-selection-dropdown">
                    <div *ngFor="let option of pageFilters" class="pageValueOption" (click)="pageFilterSelected(option, filter, i)">
                      <div fxLayoutAlign="start center">
                        <div class="page-option-row-1">{{ option.displayValue }}</div>
                      </div>
                      <div fxLayoutAlign="start center">
                        <div fxFlex="40" class="page-option-row-2">{{ option.panelType }}</div>
                        <div fxFlex="10"></div>
                        <div fxFlex="40" class="page-option-row-2">{{ option.widgetType }}</div>
                      </div>
                    </div>
                  </mat-menu> -->
                </div>
                <div *ngSwitchCase="'navigation_filter'">
                  <mat-form-field class="noBottomMargin">
                    <mat-label>Incoming Attribute</mat-label>
                    <input matInput [(value)]="filter.value" (keyup)="filterValue(filter, $event)">
                  </mat-form-field>
                </div>
              </div>

              <!-- <div *ngIf="lockFilterType">
                <mat-form-field appearance="standard" class="noBottomMargin">
                  <mat-label>Incoming Attribute</mat-label>
                  <input matInput [(value)]="filter.value" (keyup)="filterValue(filter, $event)">
                </mat-form-field>
              </div> -->

              <app-field-picker *ngIf="fieldPickerMap" class="fieldPicker"
                               [inputFieldMap]="fieldPickerMap"
                              (selectedFields)="insertFilterCursor($event, filter)"
              ></app-field-picker>
            </div>

            <div *ngIf="filter.operator === '#'" [fxFlex]="(componentWidth < 450 && componentWidth > 0) ? '100' : '25'" fxFlex.lt-md="45" fxFlex.lt-sm="75">
                <div >
                <mat-form-field class="custom_select example-full-width" >
                  <mat-label>Macro</mat-label>
                  <mat-select (selectionChange)="emitFilterOnInput()" [(ngModel)] = 'filter.value' name="type" >
                  <mat-option *ngFor="let type of macroOptions"  [value]="type.id">
                   <span>{{type.name}}</span> </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

          </div>
        </mat-card>
        <!-- <div fxFlex="5" fxLayout="column" fxLayoutGap=".5rem" fxLayoutAlign="space-around center">
          <button *ngIf="enableSecurity" mat-icon-button color="acent" fxLayoutAlign="center center" (click)="filter.isSecurity = !filter.isSecurity">
            <mat-icon>disabled_visible</mat-icon>
          </button>
          <button fxLayout="column" mat-icon-button color="warn" fxLayoutAlign="center center" (click)="removeFilter(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div> -->
        <app-security-config *ngIf="filter.isSecurity" [meta]="filter" [isNoEffect]="true">
        </app-security-config>

      </div>
    </div>
    <div style="color: brown;font-size:small;" *ngIf="!isFilterValid"> Please ensure that all selected filters have valid attributes, operations, and values.</div>
    <div *ngIf="filter.filterEnabled">
      <div style="height: 1rem"></div>
      <button  mat-stroked-button color="primary" (click)="addFilterItem()">
        <mat-icon>add</mat-icon> Add Filter
      </button>
    </div>

    <div *ngIf="filter.filterEnabled">
      <div style="height: 1rem"></div>
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap=".5rem">
        <button *ngIf="supportApplyButton" mat-stroked-button color="warn" (click)="cancelFilters()">
          Cancel
        </button>
        <button *ngIf="supportApplyButton" mat-raised-button color="primary" (click)="applyFilters()">
          Apply Filter
        </button>
      </div>
    </div>
</div>
