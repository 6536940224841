import { AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router, Route, ActivatedRoute, ParamMap, RouterEvent, Event, NavigationEnd } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PreviewDialogComponent } from '../../../modules/viewer/preview-dialog/preview-dialog.component'
import { MetaService } from '../../services/meta-service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenUtil } from 'src/app/core/services/TokenUtil.service';
import { environment } from 'src/environments/environment';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import { filter, windowWhen } from 'rxjs/operators';
import { PublishConfigComponent } from '../publish-config/publish-config.component';
import { BloomCollaborationComponent } from '../bloom-collaboration/bloom-collaboration.component';
import { BloomErrorDiologComponent } from '../bloom-error-diolog/bloom-error-diolog.component';
import { FormService } from 'src/app/form/form.service';
import { PublishDialogComponent } from 'src/app/form/publish-dialog/publish-dialog.component';
import { FlowService } from 'src/app/flow/flow.service';
import { Inject } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DeviceInfoService } from 'src/app/shared/services/device-info.service';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { PageService } from '../../services/page-service.service';
// import { PageService } from '../../services/page-service.service';

// import {  }
@Component({
  selector: 'app-builder-toolbar',
  templateUrl: './builder-toolbar.component.html',
  styleUrls: ['./builder-toolbar.component.css']
})
export class BuilderToolbarComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('bloomNameInput') bloomNameInput: ElementRef

  currentBloomId;
  previousPageMeta: any = '';
  currentPageMeta: any = '';
  currentBloomMeta;
  targetUrl;
  disabledSave: boolean = true;
  userMadeChanges: boolean = false;
  localLogo: any;
  showNameEditIcon: boolean = false;
  currentPageCode: string = ''
  textEditMode: boolean;

  routeParameterSubscription: any;
  pageMetaSubscription: any
  currentBaseUrl: string
  currentFullUrl: string
  uploading: boolean = false
  uploadingFailed: boolean = false

  userChangesSubscription: any
  userChangesSubscriptionForms: any
  userChangesSubscriptionFlows: any
  bloomMetaSubscription: any

  disablePreviewButton: boolean = false
  spinner: boolean = false

  parentType: string; // bloom | form | flow

  formMeta: any = {}

  builderHeadingMap: any = {
    'bloom': "BLOOM BUILDER",
    'flow': "FLOW BUILDER",
    'form': "FORM BUILDER",
  }
  routerSubscription: any

  @Input() flow: any;
  @Input() hide: boolean = false;
  @Output() action = new EventEmitter<any>()
  isBrowser: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private metaService: MetaService,
    private authService: AuthServiceService,
    private http: HttpClient,
    private tokenUtil: TokenUtil,
    private connectionService: ConnectionService,
    private formService: FormService,
    private flowService: FlowService,
    public deviceInfo: DeviceInfoService,
    public resourcePermission: ResourcePermissionService,
    private pageService: PageService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if(!this.isBrowser) return;
    this.routerSubscription = router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    )
      .subscribe((event: any) => {
        let url = decodeURI(event.url)
        console.log("--------------- router event decoded", url);
        let firstSplit = url.split('#')[0]
        let secondSplit = firstSplit.split('/')
        this.parentType = secondSplit[1]
        // console.log("meta.code", meta.code)
      });
  }

  ngOnInit(): void {
    if(!this.isBrowser) return;
    this.currentBloomMeta = {};
    this.currentBaseUrl = window.location.protocol + '//' + window.location.hostname
    this.currentBaseUrl = window.location.port ? this.currentBaseUrl + ":" + window.location.port : this.currentBaseUrl
    console.log("----------> current url", this.currentBaseUrl)

    this.textEditMode = false;
    this.bloomMetaSubscription = this.metaService.get_bloomMeta.subscribe(data => {
      this.currentBloomMeta = data;
      console.log("currentBloomMeta", this.currentBloomMeta)
    })

    this.formService.get_formMeta.subscribe(data => {
      this.formMeta = data
      console.log("this.formService.formMeta.value", this.formService.formMeta.value)
    })

    this.userChangesSubscription = this.metaService.$userMadeChanges.subscribe(isChanged => {
      this.disabledSave = isChanged == true ? false : true
    })

    this.userChangesSubscriptionForms = this.formService.$userMadeChanges.subscribe(isChanged => {
      this.disabledSave = isChanged == true ? false : true
    })

    this.userChangesSubscriptionFlows = this.flowService.$userMadeChanges.subscribe(isChanged => {
      this.disabledSave = isChanged == true ? false : true
      if(!isChanged) {
        this.uploading = false
      }
    })

    // this.pageMetaSubscription = this.metaService.pageMeta.subscribe(meta => {
    //   console.log("[BUILDER TOOLBAR] page meta subscription", meta)
    //   console.log("current page meta", JSON.parse(JSON.stringify(this.currentPageMeta)))
    //   // this is to ensure that pages that are not loaded in canvas are not saved as current page
    //   if (
    //     !this.currentPageMeta ||  // for initial assignment
    //     (meta.code == this.currentPageCode) // for subsequent assignments
    //   ) {
    //     this.currentPageMeta = meta
    //     this.currentPageCode = meta.code
    //   }
    // })
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    if(!this.isBrowser) return;
    this.pageMetaSubscription?.unsubscribe()
    this.userChangesSubscription?.unsubscribe()
    this.userChangesSubscriptionForms?.unsubscribe()
    this.bloomMetaSubscription?.unsubscribe()
    this.routerSubscription?.unsubscribe();
  }

  ngOnChanges(changes:any){
    // console.log("flow changes", changes)
  }

  async onShare() {
    if(this.parentType == 'form') return
    else if(this.parentType == 'bloom'){
      let dialogRef = this.dialog.open(BloomCollaborationComponent, {
        width: '90vw',
        height: '90vh',
        panelClass: "collabdiolog",
        data: {
          bloomMap: this.currentBloomMeta,
          resourceType: 'bloom',
          workspaceMap: this.connectionService.selectedWorkSpaceMap
        },
      });
      var diologResult = await dialogRef.afterClosed().toPromise();
    }else if(this.parentType == 'flow'){
      this.action.emit({actionType: 'share'})
    }
  }

  async openDialog(url) {
      const dialogRef = await this.dialog.open(PreviewDialogComponent, {
        minWidth: '100%',
        height: '100%',
        data: {
          url: url,
          parentType: this.parentType
        }
      });

      await dialogRef.afterClosed().subscribe(result => {
        this.spinner = false;
        if(this.parentType == 'form') {
          this.formService.isPreview = false;
          this.formService.isPreviewSuccess = false;
          this.formService.builderMode = true;
        }
        console.log('current url', this.router.url);
        let targetUrl = this.router.url.split('#')[0]
        this.router.navigate([targetUrl], { fragment: 'edit' });
      });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  onFlowNameClick(){
    this.flowService.flowNameChange.next(true);
  }

  async onPreview() {
    // if(this.isForm) return
    let url
    console.log("on preview called")
    this.disablePreviewButton = true

    // this.pageService.showFullPageSpinner(true, {type: 'loading-text', text: 'saving your recent changes'})

    if(this.parentType != "form")await this.onSave()

    if(this.parentType == 'form'){
      this.formService.isPreview = true;
      console.log("current url", this.currentBaseUrl)
      let urlSplits = this.router.url.split('#')[0].split('/')
      url = this.currentBaseUrl + '/form/p/' + urlSplits[urlSplits.length - 1]
    } else if(this.parentType == 'bloom'){
      this.spinner = true
      // update bloom with publisher_email so that we can use user_api_key in view mode
      this.currentBloomMeta['publisher_email'] = this.authService.userProfile.email
      try {
        let response = await this.updateBloom()
        console.log("bloom updated", response)
      } catch (e) {
        console.log("bloom update failed", e)
        return
      }
      // this.pageService.showFullPageSpinner(false)
      this.spinner = false

      //parse the current url, discard fragment and extract parts separated by /
      let urlSplits = this.router.url.split('#')[0].split('/')
      let pageCode = urlSplits[urlSplits.length - 1]
      let bloomCode = urlSplits[urlSplits.length - 2]

      url = this.currentBaseUrl + '/p/' + bloomCode + "/" + pageCode + "?v=draft"
    }
    this.disablePreviewButton = false

    this.openDialog(url);
  }

  async onBloomNameEdit() {
    console.log("bloom name edit fired")
    this.textEditMode = true;
    setTimeout(() => {
      this.bloomNameInput.nativeElement.focus()
    }, 500);
  }

  editConfirm() {
    this.textEditMode = false
    if(this.parentType == 'form'){
      console.log("form name changed", this.formMeta)
      this.formService.updateForm(this.formMeta)
      .then(response => {
        console.log("bloom name update response", response)
      })
      .catch(err => {
        console.log("error in updating bloom name", err)
      })
    }else if(this.parentType == 'bloom'){
      console.log("bloom name changed", this.currentBloomMeta)

      this.metaService.updateBloom(this.currentBloomMeta)
        .then(response => {
          console.log("bloom name update response", response)
        })
        .catch(err => {
          console.log("error in updating bloom name", err)
        })
    }
  }

  async updateBloom() {
    if(this.parentType == 'form'){
      let response = await this.formService.updateForm(this.formMeta)
      .then(response => {
        console.log("form name update response", response)
      })
      .catch(err => {
        console.log("error in updating bloom name", err)
      })
    }else if(this.parentType == 'bloom'){
      let response = await this.metaService.updateBloom(this.currentBloomMeta)
        .then(response => {
          console.log("bloom name update response", response)
        })
        .catch(err => {
          console.log("error in updating bloom name", err)
        })
    }
  }

  async onSave() {
    if (this.uploading) {
      return
    }

    this.uploading = true
    this.uploadingFailed = false

    if(this.parentType == 'form'){
      try{
        await this.formService.updateForm()
        console.log("explicit save successful")
        this.disabledSave = true
        this.uploadingFailed = false
        this.uploading = false
      }catch(e){
        console.log("explicit save failed", e)
        this.uploading = false
        this.uploadingFailed = true
      }
    } else if (this.parentType == 'bloom') {
      console.log("save clicked", this.metaService.pageMeta.value)
      if (this.pageService.currentPageCode.value == '__FOOTER__') {
        try {
          let res = await this.metaService.updatePageStructure(this.metaService.page_structure.value)
          console.log("footer explicit save successful")
          this.disabledSave = true
          this.uploadingFailed = false
          this.uploading = false
        } catch (e) {
          console.log("footer explicit save failed", e)
          this.uploading = false
          this.uploadingFailed = true
        }
      } else {
        await this.metaService.update(this.pageService.pageMeta)
          .then(res => {
            console.log("explicit save successful")
            this.disabledSave = true
            this.uploadingFailed = false
            this.uploading = false
          })
          .catch(err => {
            console.log("explicit save failed", err)
            this.uploading = false
            this.uploadingFailed = true
          })
      }
    } else if (this.parentType == 'flow'){
      this.action.emit({ actionType: 'save' })
    }
  }

  async getAutorizationTokenHeader() {
    var result = { Authorization: "" };
    var token = await this.tokenUtil.getStatelessToken();
    result.Authorization = `Bearer ${token}`;
    return result;
  }

  async changeDetected(event) {
    // if(this.isForm) return
    console.log("file selected:", event.target.files[0])
    // this.localLogo = event.target.files[0].name
    let image = event.target.files[0]
    let size: number = image.size;

    //verify file type as image
    // let re = /^image/
    // if(!re.test(image.type)){
    //   console.log("file type not image")
    //   return
    // }

    let that = this
    let img = new Image;
    let width: number;
    let height: number;
    img.src = URL.createObjectURL(image)
    img.onload = async function () {
      width = img.width
      height = img.height
      console.log("width and height", width, height)
      URL.revokeObjectURL(img.src)

      // validate dimension
      if (size > 204800) {
        console.log("image dimension exceeds limit (max size: 200KB)")
        that.openSnackBar("Please select an image under 200KB", "Ok")
        return
      }

      const headers = new HttpHeaders()
        .set(
          'Authorization',
          'PreAuthenticatedToken ' + that.connectionService.preAuthenticatedToken
        );

      let boxConfigToken: string;
      let url = environment.SERVER_BASE_URL + '/connection/id/' + environment.GCS_CONNECTION_ID
      let res: any
      try {
        res = await that.http.get(url, { headers }).toPromise()
      } catch (err) {
        console.log("cant fetch conn", err)
        return
      }

      console.log("connection received", res)
      boxConfigToken = res.data.box_token

      var file = new File([image], image.name, {
        type: image.type,
      });
      var buffer = await file.arrayBuffer();
      buffer = new Uint8Array(buffer)
      var nodeBuffer = []
      for (var i = 0; i < buffer.byteLength; i++) {
        nodeBuffer.push(buffer[i])
      }


      let payload = {
        parameters: {
          attachments: [{
            filename: that.parentType == 'form' ? `form/${that.currentBloomMeta._id}/${image.name}` : that.parentType == 'bloom' ? `bloom/${that.currentBloomMeta._id}/${image.name}` : '',
            size: image.size,
            mimetype: image.type,
            // buffer: buff
            buffer: nodeBuffer
            // buffer: 'test'
          }],
          bucket: environment.GCS_BUCKET
        }
      }


      let uploadUrl = environment.BOX_URL + '/googlecloudstorage/uploadFile'
      var options = { headers: { boxconfigToken: boxConfigToken } }

      var tokenMap = await that.getAutorizationTokenHeader();
      options.headers = Object.assign(options.headers, tokenMap)

      console.log("payload prepared:", payload)
      console.log("headers", options)
      let response: any
      try {
        response = await that.http.post(uploadUrl, payload, options).toPromise();
        console.log(`upload response ---->>>`, response);

      } catch (e) {
        console.log(e)
        throw e;
      }

      //construct the logoUrl
      let logoUrl: string = response.result.data[0].mediaLink
      if(that.parentType == 'form'){
        that.formMeta['logoUrl'] = logoUrl
        response = await that.formService.updateForm(that.formMeta)
      }else if(that.parentType == 'bloom'){
        that.currentBloomMeta.logoUrl = logoUrl
        let bloomUpdateRes = that.metaService.updateBloom(that.currentBloomMeta)
        console.log("BLOOM UPDATED", bloomUpdateRes)
      }
    }
    img.onerror = function () {
      console.log("error occurred in loading image")
      return
    }
  }

  async openPublishConfig() {
    if(this.parentType == 'form'){
      console.log("on publish called")
      // this.disablePreviewButton = true
      this.spinner = true
      // this.pageService.showFullPageSpinner(true, {type: 'loading-text', text: 'saving your recent changes'})

      let dialogRef = this.dialog.open(PublishDialogComponent, {
        minWidth: '50vw',
        minHeight: '45vh',
        data: {
          form: this.formMeta,
        },
      })
    }else if(this.parentType == 'bloom'){

      // trigger save in case pagemeta was not saved
      this.spinner = true
      await this.onSave()
      this.spinner = false

      let dialogRef = this.dialog.open(PublishConfigComponent, {
        minWidth: '70vw',
        minHeight: '90vh',
        data: {
          bloomMeta: this.currentBloomMeta,
        },
      })

      dialogRef.afterClosed().subscribe(data => {
        if (!data) {
          console.log("publish configuration dialog closed unexpectedly")
          return
        }
        console.log("publish config dialog resolved", data)
      })
    }else if(this.parentType == 'flow'){
      this.action.emit({actionType: 'publish'})
    }
  }

  async toBase64(file) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let encoded = reader.result
      console.log(encoded)

      let img = new Image;
      // img.src = JSON.stringify(encoded)
      img.src = JSON.stringify(encoded).split(',')[1]
      console.log(img.src)
      img.onload = () => {
        console.log("image loaded", img)
      }
      img.onerror = function () {
        console.log("error occurred")
      }
      return encoded
    };
    reader.onerror = error => {
      throw error
    };
  }
}
