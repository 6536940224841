import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { ConnectionPopupComponent } from './connection-popup/connection-popup.component';
import { ConnectionCache } from 'src/app/core/services/ConnectionCache';
import { ConnectionDialogComponent } from '../dialog/connection-dialog/connection.dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { PermissionService } from 'src/app/core/services/permission.service';
import { MediaMatcher } from '@angular/cdk/layout';

interface snackBarValues {
  snackBarMessage: string;
  snackBarIcon: string;
  snackBarError: boolean;
  snackBarDuration: number;
}

@Component({
  selector: 'app-connection-selection',
  templateUrl: './connection-selection.component.html',
  styleUrls: ['./connection-selection.component.scss']
})
export class ConnectionSelectionComponent implements OnInit {
  boxId: any;
  connectionId: any;
  connection: any;

  mediaMatcher: MediaQueryList;
  isMobileScreen: boolean;

  constructor(
    public con: ConnectionService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    public permission: PermissionService,
    private media: MediaMatcher
    // private spinner: SpinnerService
  ) {
    this.mediaMatcher = this.media.matchMedia('(max-width: 600px)');
    this.isMobileScreen = this.mediaMatcher.matches;
   }

  spinner: boolean = false;

  @Input() config: any;
  @Input() "existingCon": any;
  @Input() "connectionType": any;
  @Output() "selectedConnection" = new EventEmitter<any>();
  @Input() onlyDisplay: boolean = false;

  connectionListingACFrom = new UntypedFormControl();
  @ViewChild('connInput') connInput: ElementRef;

  connectionListingOptions: Observable<any[]>;
  isEdit:boolean = false;

  connections: any = [];
  isFirstFetch: boolean = true;
  snackBarObj: snackBarValues;

  openSnackBar(snackBarObj: snackBarValues) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: snackBarObj.snackBarMessage,
        iconname: snackBarObj.snackBarIcon,
      },
      duration: snackBarObj.snackBarDuration,
      horizontalPosition: 'end',
    });
  }

  async ngOnInit() {
    console.log("[CONNECTION SELECTION]")
    this.spinner = true;
    console.log("connection selection init", this.config)

    if(this.config){
      this.boxId = this.config.box_id;
    }
    try {
    if(this.existingCon && typeof this.existingCon === "string"){
      console.log("[CONNECTION SELECTION] string input", typeof this.existingCon === "string")
      if (ConnectionCache.checkAndGetValidConnection(this.existingCon)) {
        this.connection = ConnectionCache.checkAndGetValidConnection(this.existingCon);
      } else {
        this.connection = await this.con.getConnection(this.existingCon);
      }

      this.connectionId = this.connection?._id;
    } else if(this.existingCon && Object instanceof this.existingCon){
      this.connection = this.existingCon;
    }

    if(this.connection) {
      this.selectedConnection.emit(this.connection);
      this.connectionListingACFrom.setValue(this.connection);
    }

    } catch (errorObj) {
      console.log('Error in getting connection', errorObj)
      this.connInput.nativeElement.focus();
      const errorMsg = errorObj?.error?.error?.errorMap?.error || errorObj?.error?.error || "Connection is not stable! Please try new connection.";
      console.log('errMsg', errorMsg);
      this.connectionListingACFrom.setErrors({errorMsg});
      this.spinner = false;
    }


    this.spinner = false;
  }

  focusActionInput(): void {

    console.log("focussed, will call setFilter Options")
    this.setFilterOptions();
  }

  async ngAfterViewInit(){
    // this.appListingACFrom.valueChanges.pipe(startWith(''),
    // map((event: any) => event)).subscribe(async (response) => {
    //   let searchobj: any = {
    //     box_name: this.appListingACFrom.value ? `name%${this.appListingACFrom.value}%` : null,//
    //     page: `1|100`,
    //   };
    //   let boxes:any  = await this.con.getBoxes(searchobj);
    //   this.boxes = boxes?.data;
    // })
    if(this.config?.focus && !this.existingCon){
      this.focusActionInput();
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    console.log("changes in connection", changes)

    if(changes?.config?.currentValue?.focus){
      this.focusActionInput();
    }

    if(!changes?.config?.firstChange && changes?.config?.currentValue?.box_id){
      console.log("box_id changed")
      this.boxId = changes.config.currentValue?.box_id;
      if(this.boxId && this.isEdit){
        this.spinner = true;
        this.connections = await this.con.getTotalConnectionOfBox(this.boxId, this.permission.sharedWorkspaceId || this.con.workSpaceId)
        console.log("connections fetched in connection selection", this.connections)
      }
    }
    this.spinner = false;

  }


  async setFilterOptions() {
    if(this.boxId && !this.isEdit){
      console.log("inside if")
      this.spinner = true;
      let clearCache = this.isFirstFetch ? false : true;
      this.connections = await this.con.getTotalConnectionOfBox(this.boxId, this.permission.sharedWorkspaceId || this.con.workSpaceId, clearCache)
      this.spinner = false;
      // this.isFirstFetch = clearCache;
      console.log("connections fetched in connection selection", this.connections)
      this.isEdit = true;
    }
    this.connectionListingOptions = this.getFilteredOptions(this.connectionListingACFrom, this.connections, "name")
  }

  getFilteredOptions(form: UntypedFormControl, values:any, key?:any){
    return form.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value, values, key))
      );
  }

  displayFunction(subject) {
    if(!subject) return undefined;
    return subject.name;
  }

  async connectionSelected(connection: any){
    console.log("selected", connection);
    this.connectionId = connection._id;
    this.connection = connection;
    this.selectedConnection.emit(connection);
  }

  async connectionPopup(type){
    var option: any = {};
    console.log("this.connectionId;", this.connectionId)
    if(type == "edit"){
      option.connection = this.connectionId;
    } else option.box = this.boxId;
    if(this.config.workspace_id){
      option.workspace_id = this.config.workspace_id
    }
    var dialog = this.dialog.open(ConnectionPopupComponent, {
      maxWidth: '500px',
      minWidth: "300px",
      maxHeight: '500px',
      data: option
    });
    var diologResult = await dialog.afterClosed().toPromise();
    console.log("diologResult--> ", diologResult)
    if(!diologResult) return;
    this.spinner = true;
    let connection = diologResult?.connection || null;
    this.connectionListingACFrom.setValue(connection);
    this.connection = connection;
    this.selectedConnection.emit(connection);
    this.connections = await this.con.getTotalConnectionOfBox(this.boxId);
    this.setFilterOptions();
    this.spinner = false;
  }

  async deleteConnectionData(){
    console.log("this.connectionId : ", this.connectionId);
    console.log("this.connection : ", this.connection);
    let dialogRef = this.dialog.open(ConnectionDialogComponent, {
      width: '600px',
      data: { name: this.connection.name },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.spinner = true;
        this.con
          .deleteConnection(
            this.connectionId,
            this.con.preAuthenticatedToken
          )
          .then (
            async (response) => {
              this.connections = await this.con.getTotalConnectionOfBox(this.boxId, this.permission.sharedWorkspaceId || this.con.workSpaceId);
              await this.setFilterOptions();
              console.log("Connection Deleted : ",response);
              this.spinner = false;
              this.connectionListingACFrom.patchValue('')
              this.selectedConnection.emit('');
              this.snackBarObj = {
                snackBarMessage: 'Connection Deleted Successfully',
                snackBarIcon: 'check_circle',
                snackBarDuration: 2000,
                snackBarError: false,
              };
              this.openSnackBar(this.snackBarObj);
            },
            (error) => {
              console.log(error);
              this.spinner = false;
              this.snackBarObj = {
                snackBarMessage: 'Error while Deleting Connection',
                snackBarIcon: 'error',
                snackBarDuration: 6000,
                snackBarError: true,
              };
              this.openSnackBar(this.snackBarObj);
            }
          )
          .catch((e) => {
            console.log(e);
          });
      }
    });
  }

  private _filter(value: string, array?:any, key?:any): string[] {
    value = value ? value : ""
    if(typeof value != "string") return;
    const filterValue = value.toLowerCase();
    if(key){
      return array.filter(option => option[key].toLowerCase().includes(filterValue));
    } else {
      return array.filter(option => option.toLowerCase().includes(filterValue));
    }
  }

  clearConnection(e) {
    e.stopPropagation()
    this.connectionListingACFrom.patchValue('')
    this.selectedConnection.emit('');
  }
}
