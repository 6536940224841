<!-- <div style="font-size: .6rem;">{{ panelMeta.id }}</div> -->
<div
  *ngIf="builderMode"
  class="panelWrap"
  #panelWrap
  [ngStyle]="getCombinedStyles()"
  [class.hoveredNow]="panelMeta.id === myPageService.selectedPanel?.id ? false : panelMeta.id === hoveredPanelId"
  [class.selectedNow]="panelMeta.id === myPageService.selectedPanel?.id"
  [class.panelCut]="panelMeta.id === myPageService?.copiedMeta?.id && myPageService.isToolCutted"


  (mouseenter)="raisePanelMouseenter($event)" (mouseleave)="raisePanelMouseleave($event, panelMeta.id)"
  (mouseup)="resizeService.resizeMouseUpZ($event);" (mousemove)="resizeService.resizeMouseMoveZ($event);"
  (mouseup)="resizeService.resizeMouseUpX($event);" (mousemove)="resizeService.resizeMouseMoveX($event);"
  (mouseup)="resizeService.resizeMouseUpY($event);" (mousemove)="resizeService.resizeMouseMoveY($event);"
  fxLayout="row" fxLayoutAlign="space-between stretch" fxFlexFill fxLayoutGap="0"
>
  <!-- [ngClass]="{'bottomThickBorder': indicatePanelExit}" -->
  <!-- <div class="panel-top-line"></div> -->

  <div fxFlex="3" (click)="onPanelSelection($event, panelMeta.id)" class="handle-wrap" [style.visibility]="(panelMeta.id === selectedPanelId) || (panelMeta.id === hoveredPanelId) ? 'visible' : 'hidden'"
    fxLayout="column" fxLayoutAlign="center center">
    <mat-icon *ngIf="!panelMeta.noDrag" class="handle" cdkDragHandle>drag_indicator</mat-icon>
  </div>

  <div class="margin" [class.margin-left]="margin?.left" [fxFlex]="margin?.left || 0"></div>

  <div [fxFlex]="92 - ((margin?.left || 0) + (margin?.right ? (100 - margin.right) : 0))" (click)="onPanelSelection($event, panelMeta.id)" #container class="container" (mousedown)="$event.stopPropagation();" fxLayout="column">
    <div *ngIf="panelMeta.type === 'searchpanel'" class="fullWidth">
      <app-search-panel [panelMeta]="panelMeta" [builderMode]="builderMode" (newPanelMeta)="raiseNewPanelMeta($event)">
      </app-search-panel>
    </div>
    <div *ngIf="panelMeta.type === 'listpanel'" class="fullWidth">
      <app-list-panel [panelMeta]="panelMeta" [builderMode]="builderMode">
      </app-list-panel>
    </div>
    <div *ngIf="panelMeta.type === 'formpanel'" class="fullWidth">
      <app-form-panel [panelMeta]="panelMeta" [builderMode]="builderMode" (newPanelMeta)="raiseNewPanelMeta($event)">
      </app-form-panel>
    </div>
    <div *ngIf="panelMeta.type === 'detailspanel'"
    class="fullWidth">
      <app-details-panel [panelMeta]="panelMeta" [builderMode]="builderMode" (newPanelMeta)="raiseNewPanelMeta($event)">
      </app-details-panel>
    </div>

    <!-- regular panel -->
    <div *ngIf="panelMeta?.layoutMap?.list">
      <div
        #regularPanel
        *ngIf="(['regular', 'detailspanel','formpanel'].includes(panelMeta.type))"
        class="regularPanel minWidth" #panel [style.justify-content]="panelMeta.alignment || 'start'"
        [ngStyle]="{ 'user-select' : resizeService.grabber ? 'none' : 'auto' }"
      >
        <!-- [cdkDropListData]="panelMeta.layoutMap.list" [cdkDropListSortingDisabled]="true"
        (cdkDropListDropped)="dropWidget($event)" cdkDropList -->
      <!-- [class.topline]="isShowNewRowLine(panelMeta)" -->
        <ng-container *ngFor="let layoutId of panelMeta.layoutMap.list; let i = index; trackBy: trackByFn">
          <div
            class="layoutColumn"
            fxLayout="column"
            [fxFlex]="getWidgetFlexWidth(panelMeta.layoutMap[layoutId])"
            [class.layout-border]="layoutId === myPageService.selectedLayout"
            (mouseenter)="layoutMouseEnter($event, layoutId)" (mouseleave)="layoutMouseleave($event, layoutId)"
          >
          <!-- [class.topline]="isShowTopLine(layoutId, panelMeta)" -->
            <div *ngIf="panelMeta.layoutMap[layoutId].appearance === 'card'" fxLayout="column" class="cardView">
              <mat-card class="card-view-card" fxFlexFill>
                <div class="background" (click)="onLayoutSelect($event, layoutId, panelMeta.id)" [ngStyle]="backgroundStyles[layoutId]"></div>
                <ng-container *ngTemplateOutlet="layoutContent; context: { layout: layoutId, i: i }"></ng-container>
              </mat-card>
            </div>

            <div *ngIf="panelMeta.layoutMap[layoutId].appearance === 'outline'" class="outlineView">
              <div class="background" (click)="onLayoutSelect($event, layoutId, panelMeta.id)" [ngStyle]="backgroundStyles[layoutId]"></div>
              <ng-container *ngTemplateOutlet="layoutContent; context: { layout: layoutId, i: i }"></ng-container>
            </div>
            <!-- <ng-template *ngIf="panelMeta.layoutMap[layoutId].appearance === 'outline'">
            </ng-template> -->

            <div class="defaultView" *ngIf="panelMeta.layoutMap[layoutId].appearance !== 'outline' && panelMeta.layoutMap[layoutId].appearance !== 'card'">
              <div class="background" (click)="onLayoutSelect($event, layoutId, panelMeta.id)" [ngStyle]="backgroundStyles[layoutId]"></div>
              <ng-container *ngTemplateOutlet="layoutContent; context: { layout: layoutId, i: i }"></ng-container>
            </div>
            <!-- <ng-template #defaultBlock>
            </ng-template> -->
          </div>

          <mat-divider
            *ngIf="i < panelMeta.layoutMap.list.length - 1 && panelMeta.id === myPageService.selectedPanel?.id"
            class="divider"
            cdkDragBoundary=".layoutColumn"
            cdkDragLockAxis="x" cdkDrag (click)="$event.stopPropagation();"
            (mousedown)="resizeService.layoutClickedDown($event, panelMeta.layoutMap[layoutId])"
            (mouseup)="resizeService.layoutClickedUp($event, panelMeta.layoutMap[layoutId])"
            (mousemove)="resizeService.resizeMouseMoveLayout($event);"
            (cdkDragMoved)="resizeService.resizeMouseMoveLayoutOut($event, i, panelMeta.layoutMap);"
            (cdkDragEnded)="resizeService.resizeMouseMoveLayoutEnd($event, i, panelMeta.layoutMap)"
            vertical>
          </mat-divider>
        </ng-container>
      </div>

      <ng-template #layoutContent let-layout="layout" let-i="i">
        <div class="layoutContent" fxLayout="column" fxLayoutGap="0" (click)="onLayoutSelect($event, layout, panelMeta.id)">
          <!-- <div class="top-line" *ngIf="isShowTopLineForLayout === layout"></div> -->
          <ng-container *ngFor="let row of panelMeta.layoutMap[layout].list; let j = index; trackBy: trackByFn">
            <!-- <div style="font-size: .6rem;">{{ "row id = hovered ? " + row + " == " + myPageService.isShowBottomLineForRow + " ? " + (row === myPageService.isShowBottomLineForRow) }}</div> -->
            <!-- [class.topline]="isShowTopLine(layout, panelMeta)" -->
            <!-- <div style="position: absolute; font-size: .8rem; font-weight: bold;">{{ isListDisabled }}</div> -->
            <!-- [cdkDropListDisabled]="isListDisabled"  -->

            <!-- <div class="top-line" *ngIf="myPageService.isShowTopLineForRow === row"></div> -->
            <!-- <div class="top-line" *ngIf="true"></div> -->
            <div
              [id]="row"
              class="row"
              [class.top-line]="myPageService.isShowTopLineForRow === row"
              [class.bottom-line]="myPageService.isShowBottomLineForRow === row"
              fxLayout="row wrap" [style.justify-content]="getLayoutAlignment(panelMeta.layoutMap[layout], panelMeta.alignment)"
              [cdkDropListConnectedTo]="['label', 'button']"
              [cdkDropListData]="panelMeta.layoutMap[layout][row]" (cdkDropListDropped)="dropWidget($event)" cdkDropList [cdkDropListSortingDisabled]="true"
              [class.row-border]="row === myPageService.selectedRow"
              (mouseenter)="rowMouseEnter($event, row)" (mouseleave)="rowMouseleave($event, row)"
              (click)="onRowSelect($event, row, layout, panelMeta.id)"
            >
              <!-- (mousemove)="dragMove($event, null, row, layout)" -->
              <!-- [class.bottomline]="isShowBottomLine(row)" -->
              <!-- {{row}} {{myPageService.hoveredRow}} {{!hoveredWidgetId && row == myPageService.hoveredRow}} -->
              <ng-container *ngFor="let widget of panelMeta.layoutMap[layout][row]?.elements; let j = index; trackBy: trackByFn">
                <div
                  class="wid widgetBorder"
                  fxLayout="column" fxLayoutAlign="start stretch"
                  [fxFlex]="getWidgetFlexWidth(widget)"
                  [class.hoveredNow]="widget.id === myPageService.hoveredWidgetId"
                  [class.resizing]="resizeService.grabber && widget.id === resizeService.resizingWidgetMeta.id"
                  cdkDrag [cdkDragData]="widget" [cdkDragDisabled]="!builderMode" 
                  (cdkDragStarted)="widgetDragStarted($event, widget, row, layout)"
                  (cdkDragEntered)="dragEntered(widget, row, layout, panelMeta)"
                  (cdkDragMoved)="dragMove($event)"
                  (click)=" widgetClick($event, widget, row, layout);"
                  (mousedown)="$event.stopPropagation();"
                  (mouseenter)="widgetMouseenter($event, widget)"
                  (mouseleave)="widgetMouseleave($event, widget)"
                  [class.rightline]="myPageService.isShowRightLineForWidget === widget.id"
                  #widgets #wid
                >
                  <!-- (cdkDragEnded)="widgetDragEnded($event, widget)" -->
                  <!-- {{getWidgetFlexWidth(widget)}} && myPageService.widgetReadyForDrag
                    [class.bottomline]="isShowRightLine(widget) && !checkIfRowInsertPosible()"
                  -->
                  <app-widget-builder
                    [widgetMeta]="widget"
                    [panelMeta]="panelMeta"
                    [pageMeta]="pageMeta"
                    [builderMode]="builderMode"
                    [selectedWidgetId]="selectedWidgetId"
                    (newWidgetMeta)="raiseNewWidgetMeta($event)"
                    (widgetDeletion)="raiseDeleteWidget($event, layout, row)"
                    (widgetHover)="widgetHover($event, widget)"
                    (userInputReceived)="userInputHandler($event)"
                    (applyImageHeight)="applyImageHeight($event, widget, wid)"
                    style="width: 100%; height: 100%;"
                    >
                  </app-widget-builder>
                  <!-- (widgetSelection)="raiseWidgetSelection($event, layout, row, panelMeta.id)" -->


                  <!--------------- RESIZE HANDLES --------------->
                  <div
                    class="dot dot-right"
                    *ngIf="builderMode && selectedWidgetId === widget.id"
                    (click)="$event.stopPropagation()"
                    (mousedown)="resizeService.resizeMouseDownX($event, widget, i)">
                  </div>

                  <div
                    class="dot dot-bottom"
                    *ngIf="builderMode && selectedWidgetId === widget.id && yResizeableTypes.includes(widget.type)"
                    (click)="$event.stopPropagation()"
                    (mousedown)="resizeService.resizeMouseDownY($event, widget, i)">
                  </div>

                  <div
                    class="dot dot-right-bottom"
                    *ngIf="builderMode && selectedWidgetId === widget.id && zResizeableTypes.includes(widget.type)"
                    (click)="$event.stopPropagation()"
                    (mousedown)="resizeService.resizeMouseDownZ($event, widget, i)">
                  </div>
                </div>
              </ng-container>
            </div>
            <!-- <div class="bottom-line" *ngIf="myPageService.isShowBottomLineForRow === row"></div> -->
          </ng-container>
          <div class="new-row-line" *ngIf="myPageService.isShowNewRowLineForLayout === layout"></div>
        </div>
      </ng-template>

    </div>
  </div>
  <div class="margin" [class.margin-right]="margin?.right" [fxFlex]="margin?.right ? (100 - margin.right) : 0"></div>

  <div fxFlex="5" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap=".3rem" class="actionmenu"
    (mousedown)="$event.stopPropagation();"
    (click)="onPanelSelection($event, panelMeta.id)"
    [style.visibility]="(panelMeta.id === selectedPanelId) || (panelMeta.id === hoveredPanelId) ? 'visible' : 'hidden'"
  >
    <mat-icon class="deleteicon"  (click)="openPanelSettings()" matTooltip="panel settings">settings</mat-icon>
    <mat-icon class="deleteicon" *ngIf="!panelMeta.noClone" (click)="clonePanel()" matTooltip="Clone panel">content_copy</mat-icon>
    <mat-icon *ngIf="!panelMeta.noDelete" class="deleteicon" (click)="deletePanel()" matTooltip="Delete panel">delete</mat-icon>
  </div>


  <!-- <div class="panel-bottom-line"></div> -->
</div>


<!-- [ngStyle]="formService?.formMeta?.value?.theme?.selectedTheme?.frameconfig?.style ? formService?.formMeta?.value?.theme?.selectedTheme?.frameconfig?.style : ''"
  [ngStyle]="panelMeta?.background?.image?.url? {'background-image': 'url(' + panelMeta?.background?.image?.url + ')', 'background-repeat': 'no-repeat', 'background-size': 'cover'} : panelMeta?.background?.color? {'background-color': panelMeta?.background.color} : {'background-color': 'white'}" -->

<div
  *ngIf="!builderMode"
  class="panelWrap"
  [ngStyle]="getCombinedStyles()"
  fxLayout="row" fxLayoutAlign="space-between stretch" fxFlexFill fxLayoutGap="0"
>
  <div [fxFlex]="margin?.left || 0"></div>
  <div [fxFlex]="100 - ((margin?.left || 0) + (margin?.right ? (100 - margin.right) : 0))" #container class="container" (mousedown)="$event.stopPropagation();" fxLayout="column">
    <div *ngIf="panelMeta.type === 'searchpanel'" class="fullWidth">
      <app-search-panel [panelMeta]="panelMeta" [builderMode]="builderMode">
      </app-search-panel>
    </div>

    <div *ngIf="panelMeta.type === 'listpanel'" class="fullWidth">
      <app-list-panel [panelMeta]="panelMeta" [builderMode]="builderMode">
      </app-list-panel>
    </div>

    <div *ngIf="panelMeta.type === 'formpanel'" class="fullWidth">
      <app-form-panel [panelMeta]="panelMeta" [builderMode]="builderMode" [inputData]="inputRowData" (manageSpinner)="manageSpinnerForPanel($event)">
      </app-form-panel>
    </div>

    <div *ngIf="panelMeta.type === 'detailspanel'" class="fullWidth">
      <app-details-panel [panelMeta]="panelMeta" [builderMode]="builderMode" (manageSpinner)="manageSpinnerForPanel($event)">
      </app-details-panel>
    </div>

    <!-- regular panel -->
    <div
      #regularPanel
      *ngIf="(['regular', 'detailspanel','formpanel'].includes(panelMeta.type))"
      class="regularPanel minWidth" #panel [style.justify-content]="panelMeta.alignment || 'start'"
      [ngStyle]="{
        'user-select': resizeService.grabber ? 'none' : 'auto',
      }"
    >
      <ng-container *ngIf="runSpinnerForPanelIds.includes(panelMeta.id); else elseTemplate">
        <div style="width: 100%;">
          <spinner [type]="'skeleton'" [selfSpin]="true" [skeletonPresetCode]="skeletonSpinnerTypeMap[panelMeta.type]"></spinner>
        </div>
      </ng-container>
      <ng-template #elseTemplate>
        <ng-container *ngFor="let layoutId of panelMeta.layoutMap.list; let i = index; trackBy: trackByFn">
          <div
            class="layoutColumn"
            fxLayout="column"
            [fxFlex]="getWidgetFlexWidth(panelMeta.layoutMap[layoutId])">
  
            <div *ngIf="panelMeta.layoutMap[layoutId].appearance === 'card'; else outlineBlock" fxLayout="column" class="cardView">
              <mat-card class="card-view-card" fxFlexFill>
                <div class="background" [ngStyle]="backgroundStyles[layoutId]"></div>
                <ng-container *ngTemplateOutlet="layoutContentNonBuilder; context: { layout: layoutId, i: i }"></ng-container>
              </mat-card>
            </div>
  
            <ng-template #outlineBlock>
              <div *ngIf="panelMeta.layoutMap[layoutId].appearance === 'outline'; else defaultBlock" class="outlineView">
                <div class="background" [ngStyle]="backgroundStyles[layoutId]"></div>
                <ng-container *ngTemplateOutlet="layoutContentNonBuilder; context: { layout: layoutId, i: i }"></ng-container>
              </div>
            </ng-template>
  
            <ng-template #defaultBlock>
              <div class="defaultView">
                <div class="background" [ngStyle]="backgroundStyles[layoutId]"></div>
                <ng-container *ngTemplateOutlet="layoutContentNonBuilder; context: { layout: layoutId, i: i }"></ng-container>
              </div>
            </ng-template>
  
          </div>
        </ng-container>
      </ng-template>

      <ng-template #layoutContentNonBuilder let-layout="layout" let-i="i">
        <ng-container *ngFor="let row of panelMeta.layoutMap[layout].list; let j = index; trackBy: trackByFn">
          <div fxLayout="row wrap"  [style.justify-content]="getLayoutAlignment(panelMeta.layoutMap[layout], panelMeta.alignment)">
          <ng-container *ngFor="let widget of panelMeta.layoutMap[layout][row]?.elements; let j = index; trackBy: trackByFn">
            <div fxLayout="column"
              class="wid widgetBorder"
              fxLayoutAlign="start stretch"
              [fxFlex]="getWidgetFlexWidth(widget)"
              (click)=" widgetClick($event, widget); $event.stopPropagation();"
              #widgets #wid
            >

            <app-widget-wrapper
              [widgetMeta]="widget"
              [panelId]="panelMeta.id"
              [pageMeta]="pageMeta"
              [builderMode]="builderMode"
              (userInputReceived)="userInputHandler($event)"
              (applyImageHeight)="applyImageHeight($event, widget, wid)"
              (onExecuteAction)="raiseOnExecuteAction($event)"
              style="width: 100%; height: 100%;"
            >
            </app-widget-wrapper>
          </div>
          </ng-container>
          </div>
        </ng-container>
      </ng-template>
    </div>
  </div>
  <div [fxFlex]="margin?.right ? (100 - margin.right) : 0"></div>
</div>
