import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AES, enc, mode, pad } from 'crypto-js';
import { AuthServiceService } from '../../shared/services/auth-service.service';
import { PlaygroundService } from 'src/app/playground/playground.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TokenUtil } from 'src/app/core/services/TokenUtil.service';
import { ClientPlatformService } from '../../client-platform/client-platform.service';
import { AdminService } from '../admin/admin.service';
import { BoxCache } from 'src/app/core/boxInstances/BoxCache';
import { BoxInstance } from 'src/app/core/boxInstances/BoxInstance';
import { CacheService } from 'src/app/core/services/CacheService';
import { HttpCacheService } from 'src/app/core/services/HttpCacheService';
import { ConnectionCache } from 'src/app/core/services/ConnectionCache';

//Do not change!!
const DEFAULT_KEY = 'DuolcMroftalpSurvij';

class searchObject {
  box_name?: string;
  box_category?: string;
  box_organization?: string;
  sort_by?: string = 'name=ASC';
  page?: string = '1|10';
  length?: number;
  filter?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  // organizations: any = [];
  // selectedOrganization: string;
  // private changeOrg = new Subject<any>();
  organizationIds: any = [];
  selectedWorkSpace: string;
  selectedWorkSpaceMap: any = {};
  _preAuthenticatedToken: string;
  currentConnectionState: string;
  get preAuthenticatedToken() {
    return this._preAuthenticatedToken
  }
  set preAuthenticatedToken(val) {
    this._preAuthenticatedToken = val
  }
  userProfile: any;
  connectionUrl: string = environment.SERVER_BASE_URL;
  workSpaceId: any;
  noSearchFound: boolean = false;
  workSpaces: any;
  spinner: boolean = false;
  authorizationUrl: any;
  allBoxes: any[] = [];
  connectionLength: number = 0;
  isClientPlatform: boolean = false;
  totalApps: number = 0;
  isTotalAppsCount: boolean = false;
  preferredWorkspace: any;
  get http() { return this._http; }

  constructor(
    private _http: HttpClient,
    private router: Router,
    private authService: AuthServiceService,
    private pgService: PlaygroundService,
    private _snackBar: MatSnackBar,
    private tokenUtil: TokenUtil,
    public clientPlatformService: ClientPlatformService,
    public adminService: AdminService,
    private httpCs: HttpCacheService
  ) {
  }

  // generate AES email return PreAuthenticatedToken
  async getPreAuthenticatedToken() {

    if(this.preAuthenticatedToken){
      return this.preAuthenticatedToken
    }

    this.userProfile = this.authService.profile;
    if (sessionStorage && sessionStorage.getItem('_AWuserSession')){
      var obj = sessionStorage.getItem('_AWuserSession');
      var sessionObj = JSON.parse(this.authService.decryptUsingAES256(obj));
      console.log("sessionStorage sessionObj", sessionObj)
    }
    if(!this.userProfile.email && sessionObj?.user?.email) this.userProfile.email = sessionObj?.user?.email
    // if user is not logged in or email is not available (e.g. in published mode)
    // then we need to generate token using bloom publisher's email
    if(!this.userProfile || !this.userProfile.email){
      this.preAuthenticatedToken = ''
      return ''
    }
    try {
      this.preAuthenticatedToken = AES.encrypt(this.userProfile.email, DEFAULT_KEY).toString();
    } catch (e) {
      console.log(e);
    }
    return this.preAuthenticatedToken;
  }

  getPreAuthenticatedTokenForEmail(email){
    return AES.encrypt(email, DEFAULT_KEY).toString();
  }

  //AES decrypt function
  decryptors(ciphertext: any) {
    var bytes = AES.decrypt(ciphertext, DEFAULT_KEY);
    console.log(bytes.toString(enc.Utf8))
    return bytes.toString(enc.Utf8);
  }

  //getting Authorization Link by calling Get authorization Link api and open authorization URL
  async getAuthorizationLink(connection: any) {
    this.spinner = true;
    var result;
    let state = `${connection['box_id']}_${Date.now()}_${connection['_id']}`;
    let that = this;
    let url = `${environment.SERVER_BASE_URL}/auth/redirecturi/${state}`;
    this.currentConnectionState = state;
    let payload = { auth_map: {} };
    console.log(connection['authentication_map']);

    payload['auth_map']['auth_type'] = connection['authentication_map']['auth_type'];
    payload['auth_map']['token_type'] = connection['authentication_map']['token_type'];

    if (connection['authentication_map']['token_type'] == 'shared') {
      payload['auth_map']['client_id'] = connection['authentication_map']['client_id'];
      payload['auth_map']['client_secret'] = connection['authentication_map']['client_secret'];
    }

    try {
      let result = await that.http.post(url, payload).toPromise();
      this.spinner = false;
      this.pgService.spinner = false;
      this.authorizationUrl = result['url'];
      result = this.authorizationUrl;
      console.log( this.authorizationUrl );
      return result
    } catch (e){
        console.log(e);
        this.spinner = false;
        this.pgService.spinner = false;
        this.authorizationUrl = '';
        this._snackBar.open('Error While Getting Authentication URL ⚠️', 'OK');
    }
  }

  //get Box Category from box api
  async getBoxCategory() {
    let response = await this._invokeBoxAPI('box_category');
    return response;
  }

  //get Box Organization from box api
  async getBoxOrganization() {
    let response = await this._invokeBoxAPI('box_organization');
    return response;
  }

  //get BOX authTypes from box api
  async getBoxauthTypes() {
    let result = {}
    result = await BoxCache.getboxAuthTypeMap()
    if (Object.keys(result).length > 0) return result;
    let response = await this._invokeBoxAPI('box_auth');
    for(let obj of response?.data){
      result[obj.__id] = obj
    }
    BoxCache.setBoxAuthTypeCache(result);
    return result;
  }

  async getBox(boxId?: string) {
    var boxUrl = `${environment.SERVER_BASE_URL}/box/box`;
    var payload = {
          "filter": "__id=" + boxId + "|string",
          "page" : `1|1|1`
    }
    console.log("PAYLOAD",payload);
    try {
      let response:any = await this.execute(boxUrl, payload, null, true);
      // console.log("get box response:", response);
      BoxCache.setBoxCache(response.data);
      return response.data[0] || null;
    } catch(e){
      console.error("Error on get box:", e)
      throw e;
    }
  }

  //get boxes from box api
  async getBoxes(searchObj?: searchObject) {
    let payload = {};
    payload['length'] = searchObj?.length || null;
    let filterString: string = '';
    if (searchObj) {
      if (searchObj.box_name) {
        filterString = filterString + searchObj.box_name
      }
      if (searchObj.box_organization) {
        if (filterString == '') {
          filterString = filterString + searchObj.box_organization
        } else {
          filterString = filterString + ',' + searchObj.box_organization
        }
      }
      if (searchObj.box_category) {
        if (filterString == '') {
          filterString = filterString + searchObj.box_category
        } else {
          filterString = filterString + ',' + searchObj.box_category
        }
      }
      // console.log(filterString)
      let construtedFilterString = filterString ? ',' + filterString : '';
      if(searchObj?.filter) construtedFilterString = construtedFilterString + "," + searchObj?.filter;
      payload['filter'] = `status!=draft${environment.production && !this.authService.isInternalUser() ? ",status!=alpha" : ""},connectorType!=native` + construtedFilterString;

      // console.log("payload['filter']", payload['filter'])
      payload['sort'] = searchObj.sort_by
      payload['page'] = searchObj.page
    } else {
      payload['filter'] = `status!=draft${environment.production && !this.authService.isInternalUser() ? ",status!=alpha" : ""},connectorType!=native`;
      payload['sort'] = 'name=ASC'
    }
    // let response = await this._invokeBoxAPI('box', payload);

    let boxes = BoxCache.getBoxes(payload);
    // console.log("[boxes]", boxes);
    this.allBoxes = boxes;
    if (!this.isTotalAppsCount) {
      if (this.allBoxes && this.allBoxes.length == searchObj?.length) {
        this.totalApps = this.totalApps + this.allBoxes.length + 1
      } else {
        this.totalApps = this.totalApps + (this.allBoxes?.length || 0);
        this.isTotalAppsCount = true
      }
    }
    // console.log('total', this.totalApps)
    this.spinner = false;
    BoxCache.setBoxCache(this.allBoxes);
    let result = {data: boxes};
    return result;
  }

  //get all boxes from box api and set into allboxes
  private async _invokeBoxAPI(collection: string, searchObj?: searchObject) {
    let that = this;
    this.spinner = true;
    let payload = searchObj || {};
    let url = `${environment.SERVER_BASE_URL}/box/${collection}`;
    let token = this.setPreauthenticatedToken() ? this.setPreauthenticatedToken() : await this.getPreAuthenticatedToken();
    // console.log(collection)
    // console.log("payload", payload)
    const headers = new HttpHeaders().set('Authorization', `PreAuthenticatedToken ${token}`);
    console.log('all box payload', payload);
    let cacheOptions:any = {isLocalStorage: false};
    let res = await this.httpCs.post(url, payload, { headers }, cacheOptions);
    // let res = await that.http.post(url, payload, { headers }).toPromise();
    console.log('all box response', res);
    // if (collection == 'box') {
    //   this.allBoxes = res.data;
    //   if (!this.isTotalAppsCount) {
    //   if (this.allBoxes && this.allBoxes.length == searchObj?.length) {
    //     this.totalApps = this.totalApps + this.allBoxes.length + 1
    //   } else {
    //     this.totalApps = this.totalApps + (this.allBoxes?.length || 0);
    //     this.isTotalAppsCount = true
    //   }
    // }
    // console.log('total', this.totalApps)
    // this.spinner = false;
    // BoxCache.setBoxCache(this.allBoxes);
    // }
    return res
  }

  //Return Connetion and set Total Connection in connectionLenght Local Variable.
  async getTotalConnection(page: number = 1, limit: number = 5, preAuthenticatedToken?: string, workSpaceId?: string, filter?:any, isClearCache: boolean = false) {
    let that = this;
    let url = `${environment.SERVER_BASE_URL}/connection/${this.setWorkspaceId(workSpaceId)}?page=${page}&limit=${limit}`;
    if(filter) url = url + "&filter=" + filter;
    console.log(url)
    console.log("url", url);

    let chOptions: any = {keyPrefix: "connections_", isLocalStorage: false, clearCache: isClearCache};
    var httpOptions = { headers:
      { 'Authorization': this.setWorkspaceToken()}
    }
    let res: any = await that.httpCs.get(url, httpOptions, chOptions);
    this.connectionLength = res?.totalCount || 0;
    ConnectionCache.setConnectionsCache(res?.data);
    return res;
  }

  async getTotalConnectionOfBox(boxId, workSpaceId?: string, clearCache: boolean = false) {
    let url = `${environment.SERVER_BASE_URL}/connection/${workSpaceId || this.setWorkspaceId()}/connections/${boxId}`;
    let chOptions: any = {keyPrefix: "connections_", isLocalStorage: false, clearCache: clearCache};
    var httpOptions = { headers:
      { 'Authorization':this.setWorkspaceToken()}
    }

    console.log("httpOptions chOptions", chOptions, httpOptions, url)
    let res: any = await this.httpCs.get(url, httpOptions, chOptions);
    ConnectionCache.setConnectionsCache(res?.data);
    return res.data;
  }

  async updateWorkspace(workspaceMap){
    var options:any = {
      headers: {
        Authorization: 'PreAuthenticatedToken ' + this.setPreauthenticatedToken()
      }
    }

    var userId = this.authService.profile._id;
    workspaceMap.modified_by = this.authService.profile.email;
    workspaceMap.clientType = "APPIWORKS";
    workspaceMap.users = [userId];

    let url = `${this.connectionUrl}/workspace/APPIWORKS`;
    console.log("url", url)
    console.log("options", JSON.stringify(options))

    let response: any;
    try {
      response = await this.http.put(url, workspaceMap, options).toPromise();

      console.log("[Connection Service] create Workspace response: ", response);
      return response?.data[0];
    } catch(e){
        console.log("[Connection Service] create Workspace: ", e)
        throw e;
    } finally {
      //delete all cache based on prefix
      this.httpCs.deleteCacheBasedOnKeyPrefix("workspaces_");
    }
  }


  async createWorkspace(workspaceMap){
    var options:any = {
      headers: {
        Authorization: 'PreAuthenticatedToken ' + this.preAuthenticatedToken
      }
    }

    var userId = this.authService.profile._id;

    workspaceMap.created_by = this.authService.profile.email;
    workspaceMap.modified_by = this.authService.profile.email;
    workspaceMap.clientType = "APPIWORKS";
    workspaceMap.users = [userId];

    let url = `${this.connectionUrl}/workspace`;
    console.log("url", url)
    console.log("options", JSON.stringify(options))

    let response: any;
    try {
      response = await this.http.post(url, workspaceMap, options).toPromise();
      console.log("[Connection Service] create Workspace response: ", response);
      var workspace = response?.data[0];
      this.workSpaces.push(workspace);
      // this.selectedWorkSpace = workspace['_id'];
      // this.selectedWorkSpaceMap = workspace;
      // this.workSpaceId = this.selectedWorkSpace;

      //creating default permission for creator
      if(this.userProfile.email == workspace.created_by){
        var owner = await this.adminService.getOwnerRole(this.preAuthenticatedToken);
        console.log("owner", owner)
        let permissionBody = {
          user_id: this.userProfile._id,
          workspace_id: this.workSpaceId,
          status: "Active",
          created_by: this.authService.profile.email,
          roles: [owner._id]
        }
        await this.checkAndCreatePermission(permissionBody)
      }

      return workspace;
    } catch(e){
        console.log("[Connection Service] create Workspace: ", e)
        throw e;
    } finally {
      //delete all cache based on prefix
      this.httpCs.deleteCacheBasedOnKeyPrefix("workspaces_");
    }
  }

  //get workspace _id from workspace api set it into workSpaceId
  async getWorkSpaceId(publisherEmail?: string, clientType: string = 'APPIWORKS') {
    console.log("simulating?", this.authService.simulationStatus)

    let url = `${this.connectionUrl}/workspace/${clientType}`;

    if(this.workSpaceId && !this.authService.simulationChange) return this.workSpaceId

    let token;
    this.getPreAuthenticatedToken()

    // if user not logged in, use bloom publisher's email to generate token
    // if(!this.preAuthenticatedToken && publisherEmail){
    //   token = this.getPreAuthenticatedTokenForEmail(publisherEmail)
    // }else{
    //   console.log("WORKSPACE ID CAN NOT BE RETRIEVED.  AUTHENTICATED USER'S EMAIL OR PUBLISHER'S EMAIL IS REQUIRED.")
    // }

    let headers = {
      'Authorization':  this.setWorkspaceToken()
    }
    console.log('getWorkSpace id headers', headers, this.authService.userProfile);
    try {
      let response: any = await this.httpCs.get(url, { headers: headers }, {keyPrefix: "workspaces_", isLocalStorage: true});
      // let response: any = await this.http.get(url, { headers }).toPromise();
      console.log('inside getWorkspaceId), response data', response.data);
      this.workSpaces = response.data;
      // this.selectedWorkSpace = response.data[0]['_id'];
      // this.selectedWorkSpaceMap = response.data[0];
      // this.workSpaceId = this.selectedWorkSpace;
      if(this.preferredWorkspace) this.userProfile.last_workspace = this.preferredWorkspace;
      if(this.userProfile.last_workspace){
        this.selectedWorkSpaceMap = this.workSpaces.find(
          (data) => data._id == this.userProfile.last_workspace);

        if(this.selectedWorkSpaceMap) {
          this.selectedWorkSpace = this.selectedWorkSpaceMap._id;
        } else {
          this.selectedWorkSpaceMap = this.workSpaces[0];
          this.selectedWorkSpace = this.workSpaces[0]['_id'];
          this.userProfile.last_workspace = this.selectedWorkSpace;
          if(!this.authService.simulationStatus){
            await this.authService.updateUser(this.userProfile);
          }
        }

        this.workSpaceId = this.selectedWorkSpace
      } else {
        this.selectedWorkSpaceMap = this.workSpaces[0];
        this.selectedWorkSpace = this.workSpaces[0]['_id'];
        this.workSpaceId = this.selectedWorkSpace;
        this.userProfile.last_workspace = this.workSpaceId;
        if(!this.authService.simulationStatus){
          await this.authService.updateUser(this.userProfile);
        }
      }

      if(this.userProfile.email == this.selectedWorkSpaceMap.created_by){
        var owner = await this.adminService.getOwnerRole(this.preAuthenticatedToken);
        console.log("owner", owner)
        let permissionBody = {
          user_id: this.userProfile._id,
          workspace_id: this.workSpaceId,
          status: "Active",
          created_by: this.authService.profile.email,
          roles: [owner._id]
        }
        await this.checkAndCreatePermission(permissionBody);
      }

      return this.workSpaceId;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async checkAndCreatePermission(body){
    console.log("checkAndCreatePermission permission", body)
    let url = `${environment.SERVER_BASE_URL}/workspace-permission/checkandcreate`;
    var result: any = {};
    try {
      var headers = {
        'Authorization': 'PreAuthenticatedToken ' + this.setPreauthenticatedToken()
      }
      var response: any = await this.http.post(url, body, { headers: headers }).toPromise();
      console.log("checkAndCreatePermission response", response);
      result = response.data;
    } catch (e) {
      console.log("Error while getting permission", e)
    }
    return result;
  }

  //get single connection api
  /**
   *
   * @param id
   * @param authString preAuthenticated token | user_api_key
   * @param mode
   * @returns
   */
  async getConnection(id: string, authString?: string, mode: string = 'preauthenticated_token', isUseCache: boolean = true) {
    console.log("getConnection: id:", id)
    let url = `${environment.SERVER_BASE_URL}/connection/id/${id}`;
    var result = null;

    //if valid connection stored use it
    if(isUseCache) {
      let con = ConnectionCache.checkAndGetValidConnection(id);
      if(con) {
        console.log("Connection fetched from cache", con);
        result = con;
        return result;
      }
    }

    try {
      if (mode == 'preauthenticated_token') {
        // if preauthenticated token is not available, generate it now
        authString = this.setPreauthenticatedToken(authString)
        if (!authString) {
          authString = await this.getPreAuthenticatedToken()
        }

        var headers = {
          'Authorization': 'PreAuthenticatedToken ' + authString
        }
      } else if (mode == 'user_api_key') {
        var headers = {
          'Authorization': 'Basic ' + authString
        }
      } else {
        console.log("token or user api key missing. can't fetch connection")
        return null
      }
      console.log("headers prepared", headers)
      var connection: any = await this.http.get(url, { headers: headers }).toPromise();
      result = connection?.data || null;
      console.log("[CONNECTION]", result)
      ConnectionCache.setConnectionsCache([result]);
    } catch (e) {
      console.log("Error while getting connection", e)
      this.spinner = false;
      throw e;
    }
    return result;
  }



  // async checkAndRefreshToken(authentication_map) {
  //   let url = `${environment.SERVER_BASE_URL}/auth/refresh`;
  //   let response: any = null;
  //   try {
  //     response = await this.http.post(url, authentication_map).toPromise();;
  //     console.log("response refresh: ", response)
  //     return response || null;
  //   } catch (e) {
  //     console.log("error while refreshing token", e)
  //     throw e;
  //   }

  // }

  /*
    check and get box config token from box
    @param boxId boxId
    @param boxConfig boxConfig
  */
  async getBoxConfigTokenFromBox(boxId, boxConfig) {
    var endpoint = `/${boxId}/config`;
    var boxToken = null;
    try {
      // boxConfig.authType = "oauth2";
      var headers = { Authorization: "", "Content-Type": "application/json"  };
      var token = await this.tokenUtil.getStatelessToken();
      headers.Authorization = `Bearer ${token}`;
      let boxUrl = await BoxInstance.getInstance(endpoint);
      let res: any = await this.http.post(boxUrl + endpoint, { config: boxConfig }, { headers: headers }).toPromise();;
      boxToken = res?.boxConfigToken || null;
    } catch (e) {
      console.log("error on getting box token", e)
    }
    return boxToken;
  }

  setWorkspaceId(workSpaceId?: any) {
    let result = null;

    if(workSpaceId) result = workSpaceId;
    else if(this.workSpaceId) result =  this.workSpaceId;
    else if(this.clientPlatformService.workSpaceId) result =  this.clientPlatformService.workSpaceId;

    return result;
  }

  setWorkspaceToken(token?: any){
    let result = "";
    if(this.userProfile?.user_api_key){
      result = 'Basic ' + this.userProfile.user_api_key;
    } else result = 'PreAuthenticatedToken ' + this.setPreauthenticatedToken(token);
    return result;
  }

  setPreauthenticatedToken(token?: any){
    let result = null;
    if(token) return token;
    else if(this.preAuthenticatedToken) result = this.preAuthenticatedToken;
    else if(this.clientPlatformService.preAuthenticatedToken) result = this.clientPlatformService.preAuthenticatedToken;
    return result
  }

  //create new connection API
  async createConnection(payload, workSpaceId?: any) {
    console.log(workSpaceId, this.workSpaceId);
    let url = `${environment.SERVER_BASE_URL}/connection/${this.setWorkspaceId(workSpaceId)}`;
    var result: any = {};
    try {
      var headers = {
        'Authorization': 'PreAuthenticatedToken ' + this.setPreauthenticatedToken()
      }
      var connection: any = await this.http.post(url, payload, { headers: headers }).toPromise();
      console.log(connection);
      result.connection = connection?.data?.[0] || null;
      result.status = "SUCCESS";
      console.log("[CONNECTION] create: ", result)
    } catch (e) {
      console.log("Error while getting connection", e);
      throw e;
    } finally {
      this.httpCs.deleteCacheBasedOnKeyPrefix("connections_");
    }
    return result;
  }

  //Update connection API
  async updateConnection(payload, workSpaceId?: any) {
    let url = `${environment.SERVER_BASE_URL}/connection/${this.setWorkspaceId(workSpaceId)}`;
    var result: any = {};
    try {
      var headers = {
        'Authorization': 'PreAuthenticatedToken ' + this.setPreauthenticatedToken()
      }
      var connection: any = await this.http.put(url, payload, { headers: headers }).toPromise();
      console.log(connection)
      result.connection = connection?.data?.[0] || null;
      result.status = "SUCCESS";
      console.log("[CONNECTION] update: ", result)
      ConnectionCache.setConnectionsCache([result.connection]);
    } catch (e) {
      console.log("Error while getting connection", e);
      throw e;
    } finally {
      this.httpCs.deleteCacheBasedOnKeyPrefix("connections_");
    }
    return result;
  }

  //Delete Connection API
  async deleteConnection(id: string, preAuthenticatedToken?: string) {
    let url = `${environment.SERVER_BASE_URL}/connection/${id}`;
    const headers = new HttpHeaders().set(
      'Authorization',
      'PreAuthenticatedToken ' + this.setPreauthenticatedToken(preAuthenticatedToken)
    );
    let res = await this.http.delete(url, { headers }).toPromise();
    this.httpCs.deleteCacheBasedOnKeyPrefix("connections_");
    return res;
  }

  async checkConnectionConfig(endpoint: string, config: any) {
    let boxToken = null;
    let result: any = { isError: false };
    try {
      let token = this.tokenUtil.getStatelessToken();

      const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`)
      .set('Content-Type', 'application/json');
      let url = await BoxInstance.getInstance(endpoint);
      var res: any = await this.http.post(url + endpoint, config, { headers }).toPromise();
      console.log(res);
      boxToken = res?.boxConfigToken || null;
      result.boxToken = boxToken;
    } catch (e) {
      console.log("Error while getting box token", e)
      result.errorMessage = e;
      result.isError = true;
      return result
    }
    return result;
  }

  /*
    check and get box config token of connection
    @param id connection id
  */
  async getBoxConfigToken(id) {
    var connection = await this.getConnection(id);
    console.log("connection-->", connection)
    var boxConfigToken = null;
    try {
      boxConfigToken = connection.box_token;
    } catch (e) {
      console.error("Error on replacing boxtoken", e)
    }
    return boxConfigToken;
  }

  //get BOX Config API
  async getBoxConfig(endpoint: string) {
    let that = this;
    let token = this.tokenUtil.getStatelessToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`)
    .set('Content-Type', 'application/json');;
    let url = await BoxInstance.getInstance(endpoint);
    let res = await that.http.get(url + endpoint, { headers }).toPromise();
    return res;
  }

  async checkClientType(wid: any) {
    let that = this;
    let url = `${environment.SERVER_BASE_URL}/workspace/id/${wid}`;

    const headers = new HttpHeaders().set(
      'Authorization',
      'PreAuthenticatedToken ' + this.setPreauthenticatedToken()
    );
    var promise = that.http.get(url, { headers }).toPromise();
    await promise.then(
      (res: any) => {
        const currentworkspace = res.data;
        // let currentworkspace = res.find((workspace) => workspace._id == wid);

        console.log('workspace', currentworkspace)
        if (['GOOGLE-ADDON', 'ZOHOCRM'].includes(currentworkspace.client_type)) {
          this.isClientPlatform = true;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  redirectToParent() {
    if (this.clientPlatformService.clientType) {
      if(this.clientPlatformService.clientType == 'GOOGLE-ADDON') {
        this.router.navigate(['client-platform/google/google-forms/v2/services'], {
          queryParams: {
            form: this.clientPlatformService.formId,
            host: 'GOOGLE-FORMS',
          },
        });
      } else if(this.clientPlatformService.clientType == 'HUBSPOT') {
        this.router.navigate(['client-platform/hubspot/service/config'], {
          queryParams: {
            userEmail: this.clientPlatformService.clientUserId,
            portalId: this.clientPlatformService.clientInstanceId,
          },
        });
      } else if(this.clientPlatformService.clientType == 'ZOHOCRM'){
          const url: string = this.clientPlatformService.currentNavigateBackUrl;
          this.router.navigate([url], {
          queryParams: {
            workspaceid: this.clientPlatformService.workSpaceId,
            authorization: encodeURIComponent(this.clientPlatformService.preAuthenticatedToken),
          },
        });
      }
    }
  }

  async execute(url: string, payload?: any, method?: string, isUseCache?: boolean){

    let token = this.setPreauthenticatedToken() ? this.setPreauthenticatedToken() : await this.getPreAuthenticatedToken();
    var options:any = {
      headers: {
        Authorization: this.setWorkspaceToken(token)
      }
    }

    let response: any;
    try {

      let cacheOptions:any = {isLocalStorage: false};
      if(method == "get"){
        if(isUseCache){
          response = await this.httpCs.get(url, options, cacheOptions);
        } else response = await this.http.get(url, options).toPromise();

      } else {
        if(isUseCache){
          response = await this.httpCs.post(url, payload, options, cacheOptions);
        } else response = await this.http.post(url, payload, options).toPromise();

      }
      console.log("[BOX-HTTP] flow execution response: ", response);
      return response
    } catch(e){
        console.log("[BOX-HTTP] flow execution: ", e)
        throw e;
    }
  }

  //client platform workspace update
  async updateWorkspaceForClientPlatform(options: any) {
    let clientType = this.clientPlatformService.clientType;
    //check if the workspace already available in client platform then use that, else fetch from API.
    if (!this.clientPlatformService.workSpaceId) {
      await this.clientPlatformService.getWorkSpaceId(clientType)
    }
    let workspace = this.clientPlatformService.workspaceObj;
    let workspaceOptions = {...workspace.options, ...options}

    workspace.options = workspaceOptions;
    console.log(workspace);
    console.log(workspaceOptions);
    this.clientPlatformService.updateWorkspace(clientType, workspace)
  }
}
