import { Component, Inject, NgZone, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FlowService } from "../flow.service";
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { SmsService } from 'src/app/shared/services/sms-service.service';
import { MatDialog } from '@angular/material/dialog';
import { QoutaPopupComponent } from 'src/app/shared/qouta-popup/qouta-popup.component';
import { formatDate, isPlatformBrowser } from '@angular/common';
import { FlowCollaborationComponent } from '../flow-collaboration/flow-collaboration.component';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { DeleteDialogComponent } from 'src/app/shared/delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-flow-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class FlowHomeComponent implements OnInit {

  error = false;
  authError: boolean = false;
  spinner: boolean = false
  isBrowser: boolean;

  constructor(
    public router: Router,
    public fs: FlowService,
    public authService: AuthServiceService,
    public resourcePermissionService: ResourcePermissionService,
    // private spinner: SpinnerService,
    private _snackBar: MatSnackBar,
    private smsService: SmsService,
    private dialog: MatDialog,
    private themeService: ThemeService,
    private ngZone: NgZone,
    private connectionService: ConnectionService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
   }

  async ngOnInit() {
    if(!this.isBrowser) return;
    if (
      !this.connectionService.selectedWorkSpace &&
      !this.authService.loggedIn
    ) {
      console.log("Not logged in")
      // this.authService.authCheck();
      // this.authService.authCheckPositive.subscribe(async (authStatus) => {
      //   //if logged in
      //   if (authStatus) {
      //     this.spinner = true;
      //     console.log('logged in');
      //     this.themeService.loadTheme();
      //     this.themeService
      //       .getExistingTheme(this.authService.profile._id)
      //       .then((res: any) => {
      //         if (res?.data != null) {
      //           console.log(res);
      //           this.themeService.settings_Id = res?.data[0]._id;
      //           let theme = res.data[0].themeSetting.theme;
      //           this.themeService.setTheme(theme);
      //           this.themeService.currentLocale = res.data[0].localeSetting;
      //           this.themeService.textDirection =
      //             res.data[0].themeSetting.direction;
      //           if (res.data[0].themeSetting.direction == 'rtl') {
      //             this.themeService.enableDirMode('rtl');
      //           }
      //         }
      //       });
      //       console.log("[CALLING] getWorkspaceId from flow listing oninit")
      //       await this.connectionService.getWorkSpaceId();
      //   } else {
      //     this.error = true;
      //     this.authError = true;
      //     this.spinner = false;
      //     return;
      //   }
      // });

      this.authService.navigateParams = { entityType: 'flow-signup' };
      this.router.navigate(['/sign-up'], {
      });

    } else if (this.connectionService.selectedWorkSpace) {
      this.spinner = true
      console.log("workspace already selected")
      this.spinner = false
    } else {
      this.spinner = true
      console.log("authService.loggedIn", this.authService.loggedIn)
      console.log("connectionService.selectedWorkSpace", this.connectionService.selectedWorkSpace)
      let res = await this.connectionService.getWorkSpaceId()
      console.log("workspace set", res)
      this.spinner = false;
      this.fs.flowCreateConfiguration.creationMode = 'template';
      this.router.navigate(['flow', 'new']);
    }
  }

  navigateToSignUp(){
    this.router.navigate(['sign-up']);
  }

}
