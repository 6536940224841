import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { MetaService } from './meta-service';
import { WidgetManager } from '../models/WidgetManager';

@Injectable({
  providedIn: 'root'
})
export class WidgetService {

  widgetCreationRequest = new Subject<any>();
  widgetNotification = this.widgetCreationRequest.asObservable()

  widgetDragCreationRequest = new Subject<any>();
  widgetDragNotification = this.widgetDragCreationRequest.asObservable()

  widgetCloneRequest = new Subject<any>();
  widgetCloneNotification = this.widgetCloneRequest.asObservable()

  widgetDragStart = new Subject<any>();
  $widgetDragStarted = this.widgetDragStart.asObservable();

  // widgetDragEnd = new Subject<any>();
  // $widgetDragEnded = this.widgetDragEnd.asObservable();

  starchtDragCreationRequest = new Subject<any>();
  starchtDragNotification = this.starchtDragCreationRequest.asObservable();

  selectedPanelId = new BehaviorSubject<string>('');
  panelSelectionAlert = this.selectedPanelId.asObservable()

  selectedWidgetId = new BehaviorSubject<string>('');
  widgetSelectionAlert = this.selectedWidgetId.asObservable()

  widgetUpdationRequest = new Subject<any>();
  widgetUpdation = this.widgetUpdationRequest.asObservable()

  actionPanelRequest = new Subject<any>();
  starchtPanelNotification = this.actionPanelRequest.asObservable();

  widgetNotifier(widget: string){
    this.widgetCreationRequest.next(widget)
    this.widgetCreationRequest.subscribe((widgetType, options?) => {
    })
  }

  openWidgetSidebarSettings = new BehaviorSubject<any>(null);
  $openWidgetSidebarSettings = this.openWidgetSidebarSettings.asObservable();

  widgetDeselected = new Subject<any>();
  $widgetDeselected = this.widgetDeselected.asObservable();

  widgetDragNotifier(widget: string){
    this.widgetDragCreationRequest.next(widget)
  }

  widgetClone(event: any){
    this.widgetCloneRequest.next(event)
  }

  starchDragNotifier(starchMap: any){
    this.starchtDragCreationRequest.next(starchMap)
  }

  actionPanelNotifier(starchMap: any){
    this.actionPanelRequest.next(starchMap)
  }


  createNewWidgetInstance(widgetMeta: any){
    let newWidget: any = WidgetManager.getWidget(widgetMeta.type)
    Object.keys(widgetMeta).forEach(k => newWidget[k] = widgetMeta[k])
    return newWidget
  }

  /**
 * checks if a given event is bubbled up from child or a direct click on parent
 * @param event
 */
  checkIfEventBubbling(event: any): boolean {
    if(!event) return false;
    // TEST IF CLICK IS BUBBLED UP FROM CHILD OR DIRECTLY ON THIS ROW. IF DIRECT CLICK, DESELECT CHILDREN
    const currentTarget = event.currentTarget as HTMLElement;
    const target = event.target as HTMLElement;

    if (target === currentTarget) {
      console.log("direct click, deselect children")
      return false
    } else {
      console.log("event bubbled up")
      return true
    }
  }
  // checkIfEventBubbling(event: any, bubbledUpFromClass?: string): boolean {
  //   if (!event) return false;

  //   const currentTarget = event.currentTarget as HTMLElement;
  //   const target = event.target as HTMLElement;

  //   // Check if it's a direct click on the current target
  //   if (target === currentTarget) {
  //     console.log("direct click, deselect children");
  //     return false;
  //   }

  //   // If bubbledUpFromClass is provided, check if the event originated from an element with that class or its descendants
  //   if (bubbledUpFromClass) {
  //     let bubbledUpFromElement = target.closest(`.${bubbledUpFromClass}`);
  //     if (bubbledUpFromElement) {
  //       console.log(`event bubbled up from element with class: ${bubbledUpFromClass} or descendants`);
  //       return true;
  //     } else {
  //       console.log(`event did not originate from an element with class: ${bubbledUpFromClass} or descendants`);
  //       return false;
  //     }
  //   }

  //   // If no class is provided, check if the event bubbled up in general
  //   console.log("event bubbled up");
  //   return true;
  // }


  // getRowIdFromWidget

  getWidgetsFromPanel(panelMeta){
    let layoutMap = panelMeta.layoutMap;
    let widgets = [];
    if(!layoutMap) return widgets;
    layoutMap?.list?.forEach(layout => {
      layoutMap[layout]?.list?.forEach(row => {
        let rowMap = layoutMap[layout][row];
        if(rowMap?.type == 'elements' && rowMap?.elements?.length > 0) {
          widgets = widgets.concat(rowMap.elements);
        }
      });
    });

    return widgets;
  }

  createWidgetAction(type: any, resultValue: any, widgetId: string, panelId: string){
    let valueToFill: any;
    switch (type) {
      case 'label':
      case 'link':
      case 'richtext':
        valueToFill = resultValue || '${reset}'
        break;
      case 'image':
        valueToFill = resultValue || ''
        break;
      case 'embed':
        valueToFill = resultValue || '${reset}'
        break;

      case 'date':
      case 'time':
      case 'datetime':
        valueToFill = resultValue || '${reset}'
        if(valueToFill == '${reset}') break

        // value = this.widgetUtilityService.formatDateTime(value, type)
        break

      case 'object':
        valueToFill = resultValue || '${reset}'
        valueToFill = JSON.stringify(valueToFill, null, 4);
        break;

      case 'array':
        valueToFill = resultValue || '${reset}'
        valueToFill = JSON.stringify(valueToFill, null, 2);
        // format like an array
        break;
      case 'chips':
        valueToFill = resultValue || '${reset}'
        // value = JSON.stringify(value, null, '2');
        // format like an array
        break;
      case 'tags':
        valueToFill = resultValue || '${reset}'
        // value = JSON.stringify(value, null, '2');
        // format like an array
        break;
      case 'select':
        valueToFill = resultValue || '${reset}'
        break;
      case 'autocomplete':
        valueToFill = resultValue || '${reset}'
        break;
      case 'connection':
        valueToFill = resultValue || ''
        break;
      default:
        break;
    }

    let action: any = {
      action: 'widget',
      actionMap: {
        effectStyles: [],
        mapping: [],
        value: valueToFill,
        widget: panelId + '.' + widgetId,
        widgetActionType: "set_value"
      },
      event: 'click'
    }
    return action
  }

}
